import axios from 'axios';
import { Editor } from './editor';

class GenplanInput {
  constructor(inputElement) {
    this.inputElement = inputElement;

    // Загружаем все из дата-атрибутов в свойства
    // Собираем инпут на который подписываемся
    // Вешаем подписку на события изменения инпута
    // По каждому изменению идем за изображением
    // Заменяем src изображения
    // Создаем genplan, если он не создан или Вызваем refresh на генплане если он был создан ранее

    // Путь от модели к которой мы рисуем форму до модели, где лежит изображение генплана
    this.imageHolderModelPath = inputElement.dataset['imageHolderModelPath'];
    this.imageField = inputElement.dataset['imageField'];
    this.imageUrl = inputElement.dataset['imageUrl'];
    this.imageSizeName = inputElement.dataset['imageSizeName'];
    this.imgElement = inputElement.querySelector('img');
    this.editor = undefined;

    // За какой моделью мы следим - на чей инпут мы подписаны, то есть через какую модель
    // мы будем добираться до изображения
    this.baseModel = inputElement.dataset['baseModel'];
    this.baseField = inputElement.dataset['baseField'];
    this.baseFieldElement = document.getElementById(this.baseField);
    if (!this.baseFieldElement) {
      console.info('Could not find base element.', {
        baseField: this.baseField,
        baseModel: this.baseModel,
        inputElement: inputElement,
      });
    }
    this.baseValue = inputElement.dataset['baseValue'];

    this.bindEvents();
    this.render();
  }

  bindEvents() {
    if (this.baseFieldElement) {
      this.baseFieldElement.addEventListener('change', () => {
        this.render();
      });
    }
  }

  getBaseValue() {
    if (this.baseFieldElement) {
      return this.baseFieldElement.value;
    }
    return this.baseValue;
  }

  render() {
    axios.post(this.imageUrl, {
      id: this.getBaseValue(),
      modelClass: this.baseModel,
      imageSizeName: this.imageSizeName,
      imageHolderModelPath: this.imageHolderModelPath,
      imageField: this.imageField,
    }).then((response) => {
      this.renderInternal(response);
    });
  }

  renderInternal(response) {
    if (response.data.url) {
      //
      this.imgElement.src = response.data.url;
      this.showEditor();
    } else {
      this.hideEditor();
      // Скрываем поле
    }
  }

  showEditor() {
    if (!this.editor) {
      this.editor = new Editor(
        this.inputElement.querySelector('[data-genplan-editor]'),
        {},
      );
      console.log(this.editor);
    } else {
      this.editor.show();
    }
  }

  hideEditor() {
    if (this.editor) {
      this.editor.hide();
    }
  }
}

function init() {
  document.querySelectorAll('[data-genplan-input]').forEach((input) => {
    if (!input.dataset.initialized) {
      input.dataset.initialized = true;
      new GenplanInput(input);
    }
  });
}

document.addEventListener('DOMContentMutated', () => {
  init();
});
init();