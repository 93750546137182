export class Editor {
    constructor(rootElement, settings)
    {
        this.rootElement = rootElement;
        this.settings = settings;
        this.settings.color = this.getSettingsValue('color', 'red');
        this.settings.colorAdd = this.getSettingsValue('secondColor', 'blue');
        this.settings.showPoints = !!this.getSettingsValue('showPoints', false);
        this.settings.showPointPulse = !!this.getSettingsValue('showPointPulse', false);
        this.settings.pointRadius = parseInt(this.getSettingsValue('pointRadius', 5), 10);
        this.svgElement = rootElement.querySelector('[data-genplan-editor-canvas]');
        this.imgElement = rootElement.querySelector('[data-genplan-editor-img]');
        this.actionsElement = document.createElement('div');
        this.additionalPolygons = rootElement.dataset.additionalPolygons;

        if (!this.svgElement) {
            console.error('Could not svg canvas element by [data-genplan-editor-canvas] selector', {
                'editor': this.rootElement
            });
            return;
        }
        this.type = rootElement.dataset['type'] ? rootElement.dataset['type'] : 'placemark';
        this.input = document.querySelector(
            rootElement.dataset['input']
        );
        this.coordinates = [];
        if (!this.input) {
            return;
        }
        this.loadImages().then(() => {
            this.loadCoordinatesFromInput();
            this.loadAdditionalCoordinates();
            this.initActions();
            this.bind();
            this.render();
            this.show();
        });
    }

    hide()
    {
        this.rootElement.classList.remove('_initialized');
    }

    show()
    {
        this.rootElement.classList.add('_initialized');
    }

    refresh()
    {
        this.loadImages().then(() => {
            this.render();
        });
    }

    getSettingsValue(name, defaultValue)
    {
        if (this.settings[name]) {
            return this.settings[name];
        }
        if (this.rootElement.dataset[name]) {
            return this.rootElement.dataset[name];
        }
        return defaultValue;
    }

    initActions()
    {
        this.rootElement.appendChild(this.actionsElement);
        this.actionsElement.classList.add('genplan-editor__actions');

        if (this.isPlacemark()) {
            const clearAction = this.addAction('Удалить точку');
            clearAction.addEventListener('click', () => {this.clearCoordinates()})
        }

        if (this.isPolygon()) {
            const clearAction = this.addAction('Удалить все');
            clearAction.addEventListener('click', (e) => {
                e.preventDefault();
                this.clearCoordinates();
            })

            const removeLast = this.addAction('Удалить последнюю точку');
            removeLast.addEventListener('click', (e) => {
                e.preventDefault();
                this.deleteLastCoordinate();
            })
        }
    }

    addCoordinate(x, y)
    {
        if (this.isPolygon()) {
            this.coordinates.push([
            x, y
            ])
        }

        if (this.isPlacemark()) {
            this.coordinates = [x, y];
        }
        this.render();
    }

    clearCoordinates()
    {
        this.coordinates = [];
        this.render();
    }

    deleteLastCoordinate()
    {
        this.coordinates.splice(-1);
        this.render();
    }

    addAction(text)
    {
        const actionElement = document.createElement('button');
        actionElement.innerText = text;
        this.actionsElement.appendChild(actionElement);
        return actionElement;
    }

    isPlacemark()
    {
        return this.type === 'placemark';
    }

    isPolygon()
    {
        return this.type === 'polygon';
    }

    bind()
    {
        this.imgElement.addEventListener('dragstart', (e) => {
            e.preventDefault();
            return false;
        })

        this.imgElement.addEventListener('click', (e) => {
            let bounds = this.imgElement.getBoundingClientRect();
            let x = e.clientX - bounds.left;
            let y = e.clientY - bounds.top;

            this.addCoordinate(
                x * 100 / bounds.width,
                y * 100 / bounds.height
            )
        });
    }

    render()
    {
        this.clearSvg();

        const imgRect = this.imgElement.getBoundingClientRect();
      // Width = 100%
      // Height = ?
        const aspectRatio = imgRect.height / imgRect.width;
        const pxToPercent = 100 / imgRect.width;

        if (this.additionalCoordianates.length > 0) {
            this.additionalCoordianates.forEach((additionalCoordinate) => {
                const infoPathElement = this.makePathElementByCoordinates(additionalCoordinate, this.settings.colorAdd);
                this.svgElement.appendChild(infoPathElement);
            });
        }

        if (this.isPolygon() && this.coordinates.length > 0) {
            const pathElement = this.makePathElementByCoordinates(this.coordinates, this.settings.color);
            this.svgElement.appendChild(pathElement);
            if (this.settings.showPoints) {
                this.coordinates.forEach((coordinate) => {
                    this.addPoint(coordinate, pxToPercent, aspectRatio);
                })
            }
        }

        if (this.isPlacemark() && this.coordinates.length === 2) {
            this.addPoint(this.coordinates, pxToPercent, aspectRatio);
        }

        this.input.value = JSON.stringify(this.coordinates);
    }

    addPoint(coordinate, pxToPercent, aspectRatio)
    {
        const dotElement = this.makeDotElementByCoordinates(coordinate, this.settings.color, this.settings.pointRadius, pxToPercent, aspectRatio);
        this.svgElement.appendChild(dotElement);

        if (this.settings.showPointPulse) {
            const dotPulseElement = this.makeDotElementByCoordinates(coordinate, this.settings.color, this.settings.pointRadius, pxToPercent, aspectRatio);
            dotPulseElement.setAttributeNS(null, 'class', '_pulse');
            dotPulseElement.style.setProperty('--pos-x', coordinate[0] + '%');
            dotPulseElement.style.setProperty('--pos-y', coordinate[1] + '%');
            this.svgElement.appendChild(dotPulseElement);
        }
    }

    makePathElementByCoordinates(coordinates, color)
    {
        const rawCoords = [];
        coordinates.forEach((coordinate, i) => {
            const [x, y] = coordinate
            rawCoords.push(`${x} ${y}`)
        });
        const d = 'M ' + rawCoords.join(' L ') + ' Z';
        return this.getSvgNode('path', {
            d: d,
            fill: color
        });
    }

    makeDotElementByCoordinates(coordinate, color, radius, pxToPercent, aspectRatio)
    {
        return this.getSvgNode('ellipse', {
            cx: coordinate[0],
            cy: coordinate[1],
            rx: pxToPercent * radius,
            ry: pxToPercent * radius / aspectRatio,
            fill: color
        });
    }

    getSvgNode(nodeName, attributes)
    {
        const node = document.createElementNS("http://www.w3.org/2000/svg", nodeName);
        for (const attributeName in attributes) {
            node.setAttributeNS(null, attributeName, attributes[attributeName]);
        }
        return node;
    }

    clearSvg()
    {
        while (this.svgElement.lastChild) {
            this.svgElement.removeChild(this.svgElement.lastChild);
        }
    }

    loadCoordinatesFromInput()
    {
        const coordinatesRaw = this.input.value;
        let coordinates = [];
        if (coordinatesRaw) {
            coordinates = JSON.parse(coordinatesRaw);
        } else {
            this.coordinates = [];
            return;
        }
        let valid = true;
        if (!coordinates) {
            console.error('Could not parse coordinates from input: unknown structure', coordinatesRaw);
            valid = false;
        } else {
            if (this.isPlacemark() && !this.isValidPoint(coordinates)) {
                console.error('Could not parse coordinates from input: incorrect structure', coordinates);
                valid = false;
            }

            if (this.isPolygon() && Array.isArray(coordinates)) {
                coordinates.forEach((coordinate) => {
                    if (!this.isValidPoint(coordinate)) {
                        valid = false;
                    }
                })

                if (!valid) {
                      console.error('Could not parse coordinates from input: incorrect structure', coordinates);
                }
            }
        }

        if (!valid) {
            coordinates = [];
        }

        this.coordinates = coordinates;
    }

    loadAdditionalCoordinates() {
        const rawPolygons = this.additionalPolygons;
        const cleanCoordinates = [];
        if (rawPolygons) {
            const rawCoordinates = JSON.parse(rawPolygons);
            const rawArray = Object.entries(rawCoordinates);
            rawArray.forEach(([key, value]) => {
                if (value) {
                    cleanCoordinates.push(value);
                }
            });
        }

        this.additionalCoordianates = cleanCoordinates;
    }

    isValidPoint(coordinates)
    {
        return !(coordinates.length !== 2 || coordinates[0] === undefined || coordinates[1] === undefined);
    }

    loadImages()
    {
        return new Promise((resolve, reject) => {
          // Сколько изображений ожидают загрузки
            let imagesShouldBeLoaded = 0;
            let pending = false;
            const imagesElements = this.rootElement.querySelectorAll('img');
            imagesElements.forEach((imgElement) => {
                // Изображение уже загружено, пропускаем его
                if (imgElement.complete && imgElement.naturalHeight !== 0) {
                    return;
                }
                pending = true;
                imagesShouldBeLoaded++;
                imgElement.onload = () => {
                    imagesShouldBeLoaded--;
                    if (imagesShouldBeLoaded === 0) {
                        resolve();
                    }
                };
            });
        // Все изображения уже были загружены
        if (!pending) {
            resolve();
        }
        })
    }
}