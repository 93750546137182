import axios from "axios";
import element from "../../../../static/components/accordion/objects/element";
const Flow = require('@flowjs/flow.js');

class FilesField {
    options = {
        entityClassName: undefined,
        fileEntityClassName: undefined,
        url: undefined,
        uploadUrl: undefined,
        sortUrl: undefined,
        deleteUrl: undefined,

        flowData: {},
        sortData: {},
        deleteData: {},

        limit: 20,
        limitMessage: 'Sorry, you can upload up to 20 files at a time',
        maxSizeMessage: 'Sorry, uploaded file size exceeded',
        notAllowedMessage: 'Sorry, only specified file types can be uploaded',
        accept: '*', // For images: 'image/*'
        types: [], // For images: ["image/gif", "image/jpeg", "image/png"],

        /* 5 mb */
        maxFileSize: 5242880,
    };
    element = undefined;
    counter = 0;
    constructor(element, options) {
        if (element === undefined) return;

        this.element = element;
        this.options = Object.assign(this.options, options);

        this.initElements();
        this.initItems();
        this.initUploader();
    }

    initElements() {
        this.dropElement = this.element.querySelector('[data-files-field-drop]');
        this.progressBar = this.element.querySelector('[data-progress-bar]');
        this.emptyInfo = this.element.querySelector('[data-empty]');
        this.filesList = this.element.querySelector('[data-files-list]');
        this.imagesList = this.element.querySelector('[data-images-list]');
    }

    initItems() {
        this.fileItems = [];
        this.fileItems = Array.from(this.filesList.querySelectorAll('[data-file="file"]'));
        this.bindItems(this.fileItems);

        this.imageItems = [];
        this.imageItems = Array.from(this.imagesList.querySelectorAll('[data-file="image"]'));
        this.bindItems(this.imageItems);
    }

    bindItems(items) {
        items.forEach((item) => {
            this.bindDraggable(item);
            this.addRemoveListener(item);
        });
    }

    unbindItems(items) {
        items.forEach((item) => {
            this.unbindDraggable(item);
            this.removeRemoveListener(item);
        });
    }

    initUploader() {
        this.flow = new Flow({
            target: this.options.uploadUrl,
            testChunks: false,
            query: this.options.flowData,
            allowDuplicateUploads: true,
        });

        this.flow.assignBrowse(this.dropElement, false, false, this.options.accepts);
        this.flow.assignDrop(this.dropElement);

        this.flow.on('filesSubmitted', () => {
            this.counter = 0;
            this.flow.upload();
        });

        this.flow.on('fileAdded', (file, event) => {
            const fileObject = file.file;

            if (this.options.types && this.options.types.length && document.querySelector.inArray(fileObject.type, this.options.types) < 0) {
                alert(this.options.notAllowedMessage);
                return false;
            }


            if (this.options.maxFileSize < fileObject.size) {
                alert(this.options.maxSizeMessage);
                return false;
            }

            if (this.counter < this.options.limit) {
                this.counter++;

                if (this.counter > this.options.limit) {
                    alert(this.options.limitMessage);
                }
            } else {
                return false;
            }
        });

        this.flow.on('uploadStart', () => {
            this.progressBar.style.width = '0';
        });

        this.flow.on('progress', () => {
            const width = this.flow.progress() * 100;
            this.progressBar.style.width = `${width}%`;
        });

        this.flow.on('complete', () => {
            this.progressBar.style.width = '0';
            this.updateList();
        });
    }

    dragstartFunction(e) {
        e.dataTransfer.setData("position", e.target.dataset.position);
        e.dataTransfer.setData("id", e.target.dataset.id);
        e.target.closest('[data-file]').classList.add('_dragging');
    }

    dragoverFunction(e) {
        let old = document.querySelector('._over');
        old && old.classList.remove('_over');
        e.target.closest('[data-file]').classList.add('_over');
        e.preventDefault();
    }

    ondropFunction(e) {
        let from = document.querySelector('._dragging');
        from && from.classList.remove('_dragging');
        let to = document.querySelector('._over');
        to && to.classList.remove('_over');

        if (from.dataset.file !== to.dataset.file) {
            return;
        }
        let newContent = e.target.closest('[data-file]').innerHTML;
        let newContentId = e.target.closest('[data-file]').dataset.id;

        const fromElPosition = e.dataTransfer.getData('position');
        const fromElId = e.dataTransfer.getData('id');
        let fromEl = document.querySelector(`[data-file][data-position="${fromElPosition}"][data-id="${fromElId}"]`);

        const toEl = e.target.closest('[data-file]');
        toEl.innerHTML = fromEl.innerHTML;
        toEl.dataset.id = fromEl.dataset.id;
        fromEl.innerHTML = newContent;
        fromEl.dataset.id = newContentId;

        this.removeRemoveListener(toEl);
        this.removeRemoveListener(fromEl);
        this.addRemoveListener(toEl);
        this.addRemoveListener(fromEl);

        this.sort(toEl, fromEl);
    }

    bindDraggable(item) {
        item.draggable = true;
        item.ondragstart = this.dragstartFunction;
        item.ondragover = this.dragoverFunction;
        item.ondrop = this.ondropFunction.bind(this);
    }

    unbindDraggable(item) {
        item.draggable = false;
        item.offdragstart = this.dragstartFunction;
        item.offdragover = this.dragoverFunction;
        item.offdrop = this.ondropFunction.bind(this);
    }

    updateList() {
        axios.get(
            this.options.url,
        ).then(r => {
            const html = r.data;
            this.replaceData(html, 'data-files-list');
            this.replaceData(html, 'data-images-list');

            this.unbindItems(this.fileItems);
            this.unbindItems(this.imageItems);

            this.initItems();
            this.checkEmpty();
        }).catch(e => {
            console.log(e);
        });
    }

    replaceData(html, listType) {
        let page = document.createElement('div');
        page.innerHTML = html;
        let newData = page.querySelector(`[${listType}]`);
        let currentData = document.querySelector(`[${listType}]`);

        currentData.innerHTML = '';
        Array.from(newData.children).forEach((child) => {
            currentData.appendChild(child);
        });
    }

    checkEmpty() {
        if (this.fileItems.length > 0 || this.imageItems.length > 0) {
            this.emptyInfo.classList.add('_hide');
        } else {
            this.emptyInfo.classList.remove('_hide');
        }
    }

    sort(a, b) {
        axios.post(
            this.options.sortUrl,
            {
                entityClassName: this.options.fileEntityClassName,
                fileIds: [
                    a.dataset.id,
                    b.dataset.id,
                ]
            },
        ).then(r => {
            // console.log(r);
        }).catch(e => {
            console.log(e);
        });
    }

    removeFunction(e) {
        e.preventDefault();
        this.remove(e.target.closest('[data-file]'));
    }

    addRemoveListener(item) {
        item.querySelector('[data-remove]').addEventListener('click', this.removeFunction.bind(this), { once: true });
    }

    removeRemoveListener(item) {
        item.querySelector('[data-remove]').removeEventListener('click', this.removeFunction.bind(this), { once: true });
    }

    remove(item) {
        axios.post(
            this.options.deleteUrl,
            {
                entityClassName: this.options.fileEntityClassName,
                fileId: item.dataset.id
            },
            {
                validateStatus: (status) => {
                    return true;
                }
            }
        ).then((result) => {
            if (result.status === 200) {
                item.remove();
                if (item.dataset.file === 'file') {
                    this.fileItems.splice(this.fileItems.indexOf(item), 1);
                }
                if (item.dataset.file === 'image') {
                    this.imageItems.splice(this.imageItems.indexOf(item), 1);
                }

                this.checkEmpty();
            } else {
                console.log(result.data.message);
            }
        });
    }
}

document.querySelectorAll('[data-files-field]:not([data-initialized="true"])')
    .forEach((element) => {
        element.dataset.initialized = "true";
        const attachmentManager = new FilesField(element, {
            entityClassName: element.dataset.entityClassName,
            fileEntityClassName: element.dataset.fileEntityClassName,
            url: window.location.href,
            uploadUrl: '/attachment/upload',
            sortUrl: '/attachment/sort',
            deleteUrl: '/attachment/remove',
            flowData: {
                entityId: element.dataset.filesField,
                entityClassName: element.dataset.entityClassName,
                fileEntityClassName: element.dataset.fileEntityClassName,
                setterName: element.dataset.setterName,
            }
        });
    });