function connectYmaps() {
  if (document.querySelector('[data-ymaps]')) {
    return;
  }

  const script = document.createElement("script");  // create a script DOM node
  script.setAttribute('data-ymaps', 'ymaps');
  script.dataset.ymaps = 'ymaps';
  script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';
  document.head.appendChild(script);
}

function initMapInternal(element) {
  const latId = element.dataset.latField;
  const lngId = element.dataset.lngField;
  const latElement = document.querySelector(`#${latId}`);
  const lngElement = document.querySelector(`#${lngId}`);
  let center = JSON.parse(element.dataset.center);

  ymaps.ready(() => {
    let hasPoint = false;
    let lat = latElement.value;
    let lng = lngElement.value;
    lat = lat.replace(',', '.');
    lng = lng.replace(',', '.');
    if (lat && lng) {
      center = [lat, lng];
      hasPoint = true;
    }

    const points = new ymaps.GeoObjectCollection({}, {
      preset: 'islands#redDotIcon'
    });

    const map = new ymaps.Map(element, {
      center: center,
      zoom: 14,
      controls: ['zoomControl']
    });

    map.behaviors.disable('scrollZoom');

    const centerPoint = map.getCenter();
    points.add(new ymaps.GeoObject({
      geometry: {
        type: 'Point',
        coordinates: centerPoint
      }
    }));
    map.geoObjects.add(points);

    function setCoordinates(lat, lng) {
      latElement.value = lat;
      lngElement.value = lng;
    }

    function setPointTo(coords) {
      points.removeAll();
      points.add(new ymaps.GeoObject({
        geometry: {
          type: 'Point',
          coordinates: coords
        }
      }));
    }

    latElement.value = centerPoint[0].toPrecision(8);
    lngElement.value = centerPoint[1].toPrecision(8);

    map.events.add('click', (e) => {
      const coords = e.get('coords');
      setCoordinates(
          coords[0].toPrecision(8),
          coords[1].toPrecision(8)
      );
      setPointTo(coords);
    });
  });
}

function initMap(element) {
  connectYmaps();

  const watcher = setInterval(() => {
    if (!window.ymaps) {
      return;
    }
    clearInterval(watcher);
    initMapInternal(element);
  }, 100);
}


document.querySelectorAll('[data-map-field]').forEach((element) => {
  initMap(element);
});