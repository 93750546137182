document.addEventListener('DOMContentLoaded', () => {
  const link = document.querySelector('[data-col-configurator-link]');
  if (link) {
    // toggle
    link.addEventListener('click', () => {
      document.querySelector('[data-col-configurator-drop]').classList.toggle('_open');
    })

    // missclick
    document.addEventListener('click', (e) => {
      if (!e.target.closest('[data-col-configurator]')) {
        document.querySelector('[data-col-configurator-drop]').classList.remove('_open');
      }
    })
  }
});