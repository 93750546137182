function fix() {
    document.querySelectorAll('.ea-vich-image').forEach((eaVichImageElement) => {
        if (eaVichImageElement.querySelector('img')) {
            eaVichImageElement.querySelector('input').required = false;
        }
    })
    document.querySelectorAll('.ea-vich-file').forEach((eaVichFileElement) => {
        if (eaVichFileElement.querySelector('a')) {
            eaVichFileElement.querySelector('input').required = false;
            console.log("Not required");
        }
    })
}
document.addEventListener('DOMContentLoaded', () => {
    fix();
});
document.addEventListener('DOMContentMutated', () => {
    fix();
});